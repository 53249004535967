
// vue
import { computed, reactive, ref } from 'vue';

// components
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonCard,
        IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
        IonItem, IonLabel, IonList, IonListHeader, IonThumbnail,
        IonInput, IonSelect, IonSelectOption, IonTextarea, IonFabButton,
        IonRadioGroup, IonRadio, IonNote, IonSegment, IonSegmentButton,
        modalController, loadingController,
        isPlatform, } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import TermsAndConditionsModal from '@/components/TermsAndConditionsModal.vue';
import PetBreedSelectModal from '@/components/PetBreedSelectModal.vue';

// icons
import { location, close, camera, arrowForward, closeOutline, } from 'ionicons/icons';

// services
import OrderService from '@/services/OrderService';
import CartService from '@/services/CartService';

import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';
import { usePhotoGallery } from '@/composables/usePhotoGallery';

import config from '@/config';
export default {
  name: 'CheckoutPage',
  components: { IonPage, IonContent, IonGrid, IonRow, IonCol, IonCard,
                IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
                IonItem, IonLabel, IonList, IonListHeader, IonThumbnail,
                IonInput, IonSelect, IonSelectOption, IonTextarea, IonFabButton,
                IonRadioGroup, IonRadio, IonNote, IonSegment, IonSegmentButton,
                PageHeader, },
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { presentPrompt, presentAlert, openModal, openImageModal, } = utils();
    const { photos, takePhoto, deletePhoto, } = usePhotoGallery();
    
    const estimatedDeliveryDate = computed(() => store.getters.estimatedDeliveryDate(locale.value));
    const estimatedDeliveryDateBabe = computed(() => store.getters.estimatedDeliveryDateBabe(locale.value));
    const pickupPoints = computed(() => store.state.pickupPoints);
    const user = computed(() => store.state.user);
    const loading = ref(true);
    const cartItems = ref<any>(null);
    const isOrderingBabeProduct = () => (cartItems.value[0].product == '寵物芭比娃娃');
    const order = reactive({
      pickupPoint: "",
      deliveryMethod: "送貨上門",
      deliveryAddress: user.value.deliveryAddress || "",
      paymentMethod: "card",
      customerName: user.value.firstName || "",
      contactPhone: user.value.phone || "",
      customerEmail: user.value.email || "",
      totalPrice: null,
      stripeToken: null,
      remark: "",

      petFeatureRemark: "",
      petTypeName: "",

      discountCode: "",
      appliedDiscountCode: "",
      appliedDiscountType: "",
      appliedDiscountValue: 0,
      
      submittingOrder: false,
    });
    const checkingDiscountCode = ref(false);

    const stripe = ref<Stripe>(null);
    const card = ref<any>(null);
    const cardElement = ref<any>(null);
    const cardErrors = ref<any>(null);
    const inviInput = ref<any>(null); // FOR FIXING IOS KEYBOARD PROBLEM

    loadStripe(config.Stripe.publishableKey).then((res: Stripe) => {
      stripe.value = res;
      const elements = res.elements();

      /**
       * Credit Cards
       */
      const style = {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };
      card.value = elements.create('card', { hidePostalCode: true, style }); // Create an instance of the card Element.
      card.value.mount(cardElement.value); // Add an instance of the card Element into the `card-element` <div>.

      // Handle real-time validation errors from the card Element.
      card.value.on('change', (ev: any) => {
        cardErrors.value.textContent = ev.error ? ev.error.message : '';
      });

      if (isPlatform('ios')) { // iOS bug fix
        card.value.on('blur', (e: any) => {
          inviInput.value.focus();
          setTimeout(() => inviInput.value.blur());
        })
      }
    })

    // methods
    const passedCartItems: any = route.params.passedCartItems; // route paramters (mainly ID)
    if (passedCartItems) { // from cart page
      loading.value = false;
      cartItems.value = JSON.parse(passedCartItems);
    } else {
      CartService.getUserCartItems().then(res => {
        loading.value = false;
        cartItems.value = res;
      });
    }
    const getCartTotal = () => (cartItems.value.reduce((a: any, b: any) => +a + (b.unitPrice * b.quantity), 0));

    const getDiscountTotal = () => {
      const cartTotal = getCartTotal();
      if (order.appliedDiscountCode == "") return 0;
      if (order.appliedDiscountType == "fixed_amount") return Math.min(cartTotal, order.appliedDiscountValue);
      return cartTotal - cartTotal * (1-order.appliedDiscountValue); // percentage discount
    }
    const getOrderTotal = () => (getCartTotal() - Number(getDiscountTotal()));

    const submitOrder = async (order: any) => {
      const dismissLoading = () => {
        loadingController.dismiss();
        order.submittingOrder = false;
      }
      presentPrompt( t('CheckoutPage.confirmOrder'), async () => {
        const loading = await loadingController.create({});
        await loading.present();

        order.submittingOrder = true;
        order.subtotal = getCartTotal();
        order.discountTotal = getDiscountTotal();
        order.totalPrice = getOrderTotal();

        const { paymentMethod } = order;

        if (paymentMethod == 'card') {
          const result = await stripe.value.createToken(card.value);
          if (result.error) {
            // Inform the user if there was an error.
            cardErrors.value.textContent = result.error.message;
            dismissLoading();
            return false;
          } else {
            order.stripeToken = result.token.id; // pass the token to server for creating a payment
          }
        }
        const res: any = await OrderService.createNewOrder(order, cartItems.value, photos.value, passedCartItems == null);
        console.log(res);
        const { id, code, status } = res.order;
        
        // add the new order to store
        store.commit('addNewUserOrders', [{ ...res.order, deliveryDate: (isOrderingBabeProduct() ? estimatedDeliveryDateBabe : estimatedDeliveryDate) }]);

        // add order to device session
        const currOrderIds = JSON.parse(localStorage.getItem(config.orderIdsLocalStorageKey) || '[]');
        localStorage.setItem(config.orderIdsLocalStorageKey, JSON.stringify([id, ...currOrderIds]));
        
        //store.commit('receiveCartItems', []); // clear cart after submitting orders
        router.replace({ name: 'ThankYouPage', params: { orderId: id, orderCode: code, orderStatus: status, paymentMethod } });
        dismissLoading();
      } );
    }
    const onPickupPointChanged = (e: any) => {
      order.deliveryAddress = pickupPoints.value.find((p: any) => p.id == e.target.value).address;
    }

    const openTCModal = async () => await openModal(TermsAndConditionsModal, {});

    /**
     * Discounts
     */
    const applyDiscountCode = async () => {
      checkingDiscountCode.value = true;
      const res = await OrderService.getDiscount(order.discountCode);
      checkingDiscountCode.value = false;

      if (res) { // discount code exists
        order.appliedDiscountCode = res.code;
        order.appliedDiscountType = res.type;
        order.appliedDiscountValue = res.type == "fixed_amount" ? res.fixedAmountValue : res.percentageValue;
      }
      else { // invalid discount code
        order.discountCode = "";
        presentAlert(t('CheckoutPage.invalidDiscountCode'));
      }
    }
    const resetDiscountCode = () => {
      order.discountCode = "";
      order.appliedDiscountCode = "";
      order.appliedDiscountType = "";
      order.appliedDiscountValue = 0;
    }

    let breedSelectModalOpened = false;
    const openBreedSelectModal = async () => {
      if (!breedSelectModalOpened) {
        breedSelectModalOpened = true;
        const modal = await modalController.create({
          component: PetBreedSelectModal,
          componentProps: { pets: store.state.allPets },
        });
        modal.onDidDismiss().then(({ data }) => {
          if (data && data.selectedBreed !== null) {
            order.petTypeName = data.selectedBreed.trim();
          }
          breedSelectModalOpened = false;
        });
        return modal.present();
      }
    }
    
    return {
      // icons
      location, close, camera, arrowForward, closeOutline,
      
      // variables
      loading, cartItems, order, pickupPoints,
      paymentMethods: config.paymentMethods,
      inviInput, cardElement, cardErrors,
      photos, estimatedDeliveryDate, estimatedDeliveryDateBabe,
      checkingDiscountCode,

      // methods
      t,
      submitOrder, getCartTotal, getOrderTotal,
      onPickupPointChanged,
      openImageModal, takePhoto, deletePhoto,
      openTCModal,
      getDiscountTotal, applyDiscountCode, resetDiscountCode,
      isOrderingBabeProduct,
      openBreedSelectModal,
    }
  },
}
