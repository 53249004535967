
// Vue reactivity
import { computed, defineComponent } from 'vue';

// icons
import { arrowBackOutline, close, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon,
        IonContent, IonGrid, IonRow, IonCol, IonItem, IonLabel,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle,
        modalController, } from '@ionic/vue';

// composables
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TermsAndConditionsModal',
  props: [],
  components: { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon,
                IonContent, IonGrid, IonRow, IonCol, IonItem, IonLabel, 
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const { t } = useI18n();
    const store = useStore();
    const settings = computed(() => store.state.settings);

    // 2. methods or filters
    const closeModal = async () => { await modalController.dismiss() };

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBackOutline, close,

      // variables
      settings,

      // methods
      t, closeModal,
    }
  },
})
